@import '../../../ui-styles/src/lib/sass/_yo-variables.sass'
@import '../../../ui-styles/src/lib/sass/_yo-mixins.sass'
@import '../../../../node_modules/bootstrap/scss/_functions.scss'
@import '../../../../node_modules/bootstrap/scss/_variables.scss'
@import '../../../ui-styles/src/lib/sass/_yo-breakpoints.sass'

.iconCard
  box-shadow: none
  transition: 0.2s
  text-align: center
  margin-bottom: 16px
  border-radius: $border-radius-desktop-lg !important
  @include dropShadowSm()
  \:global(.card-body)
    display: flex
    flex-direction: column
    align-content: center
    align-items: center
    justify-items: center
    justify-content: center
    height: 200px

    a
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      align-content: center
      align-items: center
      justify-items: center
      justify-content: center
      &:hover
        cursor: pointer
        .icon
          i
            color: $danger
        \:global(.card-title)
          color: $danger !important

    \:global(.card-title)
      font-weight: 400
      color: $blue
    .icon
      margin-bottom: 20px
      i
        font-size: 80px
        color: $blue
        transition: 0.2s
        margin: 0 auto 10px auto
      img
        height: 100px
        width: auto

  //&:hover
  //  @include dropShadowLg
  //  \:global(.card-title), :global(.card-text)
  //    color: $primary
  //  .icon
  //    i
  //      color: $primary

  @media screen and (max-width: $mobileActive)
    flex-direction: row
    height: 60px !important
    \:global(.card-body)
      padding: 5px 0
      display: flex
      flex-direction: row
      justify-content: flex-start
      overflow: hidden
      position: relative
      height: 100%
      a
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: flex-start
        overflow: hidden
      \:global(.card-title)
        margin: 0
        padding: 0
      .icon
        margin: 0
        padding: 0
        width: 100px
        i
          font-size: 40px
          margin: 0
        img
          height: 40px
