@import "../../../../../libs/ui-styles/src/lib/sass/yo"

.users
  table
    table
      padding: 0
      margin: 0
      tbody
        padding: 0
        margin: 0
        tr
          border-bottom: 1px solid $gray-300
          &:last-child
            border-bottom: 0
        td
          padding: 0
          margin: 0
    \:global(.width-200px)
      width: 200px !important
