@import "../../../../../node_modules/bootstrap/scss/functions"
@import "../../../../../node_modules/bootstrap/scss/variables"

.navTabs
  border-bottom-width: 0
  \:global(.nav-item)
    :global(.nav-link)
      border-top-left-radius: 10px !important
      border-top-right-radius: 10px !important
      margin-right: 1px
      padding-bottom: 5px
      border: none
      border-bottom: 5px solid transparent
      background-color: $gray-400
      span
        color: $gray-900

      &:global(.active)
        background-color: $gray-800
        border-bottom: 5px solid $gray-800
        span
          color: $white
      &:hover
        background-color: shade-color($gray-400, 20%)
        border-bottom: 5px solid shade-color($gray-400, 20%)
        span
          color: $white

